<template>
  <div class="col-md-12">
    <div class="card card-custom mb-3">
      <div class="card-header">
        <div class="card-title">
          <div class="mr-4 h1">
            <router-link to="/ticketing"
              ><div><b-icon-arrow-left></b-icon-arrow-left></div
            ></router-link>
          </div>
          <div class="mt-2 mt-2 ml-2">
            <h3 class="card-label cardLabelTiket">History</h3>
          </div>
        </div>
        <div class="card-toolbar">
          <date-range-picker
            v-model="dateRange"
            :autoApply="true"
            :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
            @update="updateValues"
          >
            <div slot="header" slot-scope="header" class="slot">
              <h3 style="color:#3F4254; margin:12px;">Export To Excel</h3>
              <span v-if="header.in_selection"> - in selection</span>
            </div>
            <template #input="picker" style="min-width: 350px;">
              {{ picker.startDate | date }} - {{ picker.endDate | date }}
            </template>
            <template #ranges="ranges">
              <div class="ranges">
                <ul>
                  <li
                    v-for="(range, name) in ranges.ranges"
                    :key="name"
                    @click="ranges.clickRange(range)"
                  >
                    <b>{{ name }}</b>
                    <small class="text-muted"
                      >{{ range[0].toDateString() }} -
                      {{ range[1].toDateString() }}</small
                    >
                  </li>
                </ul>
              </div>
            </template>
            <!--    footer slot-->
            <!-- <div slot="footer" slot-scope="data" class="slot">
              <div style="margin-left: 12px">
                <a
                  @click="submitExport(data)"
                  v-if="!data.in_selection"
                  class="btn btn-primary btn-sm"
                  >Export Excel</a
                >
              </div>
            </div> -->
          </date-range-picker>
          <div class="ml-2 mr-2">
            <b-button
              :disabled="!dataselected"
              @click="submitExport()"
              size="sm"
              variant="secondary"
              >Export</b-button
            >
          </div>
          <div class="mr-2">
            <b-button
              @click="submitExportAll()"
              variant="secondary"
              size="sm"
              >Export All</b-button
            >
          </div>
        </div>
      </div>
      <div style="margin: 2%">
        <DxDataGrid
          :ref="my - data - grid"
          :data-source="itemsuser"
          key-expr="site_id"
          :show-borders="true"
          :word-wrap-enabled="false"
          :column-auto-width="false"
          :remote-operations="true"
          :column-hiding-enabled="false"
          @exporting="onExporting"
        >
          <DxSearchPanel :visible="true" />
          <DxFilterRow :visible="false" />
          <DxExport :enabled="false" />
          <DxColumn data-field="site_id" caption="Site ID" :width="100" />
          <DxColumn data-field="ticket_id" caption="Tiket ID" />
          <DxColumn data-field="site_location" caption="Site Location" />
          <DxColumn data-field="problem" caption="Problem" />
          <DxColumn data-field="asal_tiket" caption="Jenis Tiket" />
          <DxColumn data-field="description" caption="Deskripsi" />
          <DxColumn
            data-field="start_date"
            caption="Start Date"
            data-type="date"
            format="dd/MM/yyyy HH:mm:ss"
          />
          <DxColumn
            data-field="closed_at"
            caption="Close Date"
            data-type="date"
            format="dd/MM/yyyy HH:mm:ss"
          />
          <DxColumn :width="100" type="buttons" caption="Aksi">
            <DxButton icon="fa fa-eye" hint="Lihat" :onClick="lihatSemua" />
          </DxColumn>
          <DxPaging :page-size="10" />
          <DxPager
            :visible="true"
            :show-page-size-selector="showPageSizeSelector"
            :allowed-page-sizes="pageSizes"
          />
        </DxDataGrid>
      </div>
    </div>
    <b-modal
      v-model="editearly"
      size="xl"
      ref="my-modaledit"
      title="Detail Tiket"
      hide-footer
      centered
    >
      <b-form class="mt-4">
        <DxDataGrid
          :ref="dataGridRefKey"
          :data-source="dataSourceView"
          key-expr="id"
          :show-borders="true"
          :word-wrap-enabled="false"
          :column-auto-width="false"
          :remote-operations="true"
          :column-hiding-enabled="false"
        >
          <DxExport :enabled="true" />
          <DxColumn data-field="ticket_id" caption="Ticket ID" />
          <DxColumn data-field="site_id" caption="Site ID" />
          <DxColumn data-field="site_location" caption="Location" />
          <DxColumn data-field="box_sn" caption="Serial Number" />
          <DxColumn data-field="problem" caption="Problem" />
          <DxColumn data-field="description" caption="Deskripsi" />
          <DxColumn data-field="status" caption="Status" />
          <DxColumn
            data-field="created_date"
            caption="Created Date"
            data-type="date"
            format="dd/MM/yyyy HH:mm:ss"
          />
          <DxColumn
            data-field="updated_at"
            caption="Updated At"
            data-type="date"
            format="dd/MM/yyyy HH:mm:ss"
          />
          <DxPaging :page-size="10" />
          <DxPager
            :visible="true"
            :show-page-size-selector="showPageSizeSelector"
            :allowed-page-sizes="pageSizes"
          />
        </DxDataGrid>
      </b-form>
      <!-- <pre>{{ infoModal.content }}</pre> -->
    </b-modal>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxSearchPanel,
  DxFilterRow,
  DxColumn,
  DxExport,
  DxButton
} from "devextreme-vue/data-grid";
import moment from "moment";
import { customers } from "../../mockjson";
import WebService from "../../core/services/api";
import EventBus from "../../core/services/common/EventBus";
import Swal from "sweetalert2";
import { isNotEmpty } from "../../core/Apputil/Dx";
import CustomStore from "devextreme/data/custom_store";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import * as XLSX from "xlsx/xlsx.mjs";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
const dataGridRefKey = "my-data-grid-view";
export default {
  mixins: [validationMixin],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    DxDataGrid,
    DxSearchPanel,
    DxFilterRow,
    DxColumn,
    DxExport,
    DxButton,
    DateRangePicker
  },
  data() {
    let startDate = new Date();
    let endDate = new Date();
    endDate.setDate(endDate.getDate() + 6);
    return {
      selectedcat: null,
      selectedstatus: null,
      tanggal_mulai: null,
      tanggal_akhir: null,
      modalShowImport: false,
      dataselected: false,
      dateRange: { startDate, endDate },
      optionscat: [
        { value: null, text: "Please select a category" },
        { value: "a", text: "Panel Offline" },
        { value: "b", text: "Category 2" }
      ],
      optionstatus: [
        { value: null, text: "Please select a status" },
        { value: "new", text: "new" },
        { value: "process", text: "Process" }
      ],
      itemhistory: [],
      fieldseditearly: [
        {
          key: "index",
          label: "No",
          class: "text-center"
        },
        // {
        //   key: "old_data",
        //   label: "Description",
        //   sortable: false,
        //   class: "text-center",
        // },
        {
          key: "old_data",
          label: "Problem",
          sortable: false,
          class: "text-center"
        },
        {
          key: "created_at",
          label: "Update Time",
          sortable: false,
          class: "text-center"
        },
        {
          key: "user",
          label: "PIC / Operator",
          sortable: false,
          class: "text-center"
        },
        {
          formatter: value => {
            return value ? "Yes" : "No";
          },

          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true
        }
      ],
      form: {
        tiketid: "",
        siteid: "",
        sitelocation: "",
        saber: "",
        problem: "",
        description: ""
      },
      formEdit: {
        tiketid: "",
        siteid: "",
        sitelocation: "",
        saber: "",
        problem: "",
        last_visit: "",
        description: ""
      },
      dataGridRefKey,
      editearly: false,
      dataSource: customers,
      detaildata: "",
      value2: "",
      tipeTicket: "",
      description: "",
      valuetimevisit: "",
      searchQuery: null,
      editvisit: false,
      modalShow: false,
      showModalEdit: false,
      ArrayExcel: [],
      excelData: [],
      itemsuser: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: ""
      },
      selectedstatdev: null,
      selectedstatusvisit: null,

      optionsstatusvisit: [
        { value: null, text: "Select Status Device" },
        { value: "a", text: "Active" },
        { value: "b", text: "Non Active" },
        { value: "c", text: "Pending Device" },
        { value: "d", text: "--" }
      ],
      optionsstatdev: [
        { value: null, text: "Select Status Visit" },
        { value: "a", text: "Priority" },
        { value: "b", text: "Priority" },
        { value: "c", text: "Priority" },
        { value: "d", text: "Priority" }
      ]
    };
  },
  filters: {
    dateCell(value) {
      let dt = new Date(value);

      return dt.getDate();
    },
    date(val) {
      return moment(String(val)).format('MM/DD/YYYY');
    }
  },

  computed: {
    resultQuery() {
      if (this.searchQuery) {
        return this.items.filter(item => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every(v => item.fields.toLowerCase().includes(v));
        });
      } else {
        return this.items;
      }
    }
  },
  validations: {
    form: {
      tiketid: {
        required
      },
      siteid: {
        required
      },
      sitelocation: {
        required
      },
      saber: {
        required
      },
      problem: {
        required
      }
    },
    formEdit: {}
  },
  mounted() {
    this.getDataTable();
  },
  methods: {
    info(item, index, button) {
      console.log(item, index, button);
      this.infoModal.title = `Detail`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    format_date(value) {
      if (value) {
        return moment(String(value))
          .lang("id")
          .format("DD MMMM YYYY hh:mm");
      }
    },
    onFileChange(event) {
      var input = event.target;
      var reader = new FileReader();
      reader.onload = () => {
        var fileData = reader.result;
        var wb = XLSX.read(fileData, { type: "binary" });
        wb.SheetNames.forEach(sheetName => {
          var rowObj = XLSX.utils.sheet_to_row_object_array(
            wb.Sheets[sheetName]
          );
          this.excelData = rowObj;
        });
      };
      reader.readAsBinaryString(input.files[0]);
    },
    importBulk() {
      Swal.fire({
        title: "Konfirmasi",
        text: "Pastikan kembali data sudah sesuai.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.excelData.forEach(items => {
            let data = {
              site_id: items.site_id,
              ticket_id: String(items.ticket_id),
              site_location: items.site_location,
              saber: items.saber,
              problem: items.problem,
              last_visit: items.last_visit,
              description: items.description
            };
            WebService.addMaintenance(data)
              .then(data => {
                Swal.fire({
                  position: "center",
                  icon: data.status,
                  title: "Data berhasil ditambah",
                  showConfirmButton: false,
                  timer: 1500
                });
                this.resetForm();
                this.hideModal();
                this.getDataTable();
              })
              .catch(error => {
                console.log(error);
                Swal.fire({
                  title: "Kesalahan",
                  text: "Terjadi Kesalahan",
                  icon: "error",
                  showCancelButton: false,
                  confirmButtonText: "Kembali",
                  confirmButtonColor: "#18328D"
                });
              });
          });
        }
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    validateStateFormEdit(name) {
      const { $dirty, $error } = this.$v.formEdit[name];
      return $dirty ? !$error : null;
    },
    onSubmitEdit() {
      this.$v.formEdit.$touch();
      if (this.$v.formEdit.$anyError) {
        return;
      }
      let id = this.formEdit.id;
      let data = {
        site_id: this.formEdit.site_id,
        ticket_id: this.formEdit.ticket_id,
        site_location: this.formEdit.site_location,
        saber: this.formEdit.saber,
        problem: this.formEdit.problem,
        status: this.tipeTicket,
        description: this.description
        // last_visit: this.value2 + 'T' + this.valuetimevisit + 'Z'
      };
      Swal.fire({
        title: "Konfirmasi",
        text: "Pastikan kembali data sudah sesuai.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return WebService.editMaintenance(data, id)
            .then(data => {
              console.log(data);
              Swal.fire({
                position: "center",
                title: "Data berhasil Dirubah",
                showConfirmButton: false,
                timer: 1500
              });
              this.resetFormEdit();
              this.hideModal();
              this.getDataTable();
            })
            .catch(error => {
              console.log(error);
              Swal.fire({
                title: "Kesalahan",
                text: "Gagal mengubah data",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Kembali",
                confirmButtonColor: "#18328D"
              });
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      let data = {
        site_id: this.form.siteid,
        ticket_id: this.form.tiketid,
        site_location: this.form.sitelocation,
        saber: this.form.saber,
        problem: this.form.problem,
        description: this.form.description,
        status: "new",
        last_visit: this.value2 + "T00:00:00Z"
      };

      Swal.fire({
        title: "Konfirmasi",
        text: "Pastikan kembali data sudah sesuai.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return WebService.addMaintenance(data)
            .then(data => {
              Swal.fire({
                position: "center",
                icon: data.status,
                title: "Data berhasil ditambah",
                showConfirmButton: false,
                timer: 1500
              });
              this.resetForm();
              this.hideModal();
              this.getDataTable();
            })
            .catch(error => {
              console.log(error);
              Swal.fire({
                title: "Kesalahan",
                text: "Terjadi Kesalahan",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Kembali",
                confirmButtonColor: "#18328D"
              });
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    updateValues(value) {
      this.dataselected = true;
      this.tanggal_mulai = moment(value.startDate).format("YYYY-MM-DD");
      this.tanggal_akhir = moment(value.endDate).format("YYYY-MM-DD");
      console.log(this.tanggal_mulai, this.tanggal_akhir);
    },
    submitExport() {
      let param = {
        start_date: moment(this.tanggal_mulai).subtract(7, 'hours').format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment(this.tanggal_akhir).subtract(7, 'hours').format("YYYY-MM-DD HH:mm:ss"),
        asal: ""
      };
      WebService.exportHistory(param)
        .then(response => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", 'HistoryTiket');
          document.body.appendChild(link);
          link.click();
        })
        .catch(error => {
          console.log(error);
        });
    },
    submitExportAll() {
      let param = {
        start_date: "",
        end_date: "",
        asal: ""
      };
      WebService.exportHistory(param)
        .then(response => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", 'HistoryTiket');
          document.body.appendChild(link);
          link.click();
        })
        .catch(error => {
          console.log(error);
        });
    },
    resetForm() {
      this.form = {
        tiketid: "",
        siteid: "",
        sitelocation: "",
        saber: "",
        problem: "",
        description: ""
      };
      this.$nextTick(() => {
        this.$bvModal.hide("add-new-modal");
      });
    },
    hapus(value) {
      const id = value.row.data.id;
      Swal.fire({
        title: "Konfirmasi",
        text: "Apakah Yakin Data Dihapus?.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "OK",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return WebService.deleteMaintenance(id)
            .then(data => {
              Swal.fire({
                position: "center",
                icon: data.status,
                title: "Data berhasil Dihapus",
                showConfirmButton: false,
                timer: 1500
              });
              this.resetForm();
              this.getDataTable();
            })
            .catch(error => {
              console.log(error);
              Swal.fire({
                title: "Kesalahan",
                text: "Data Gagal Dihapus",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Kembali",
                confirmButtonColor: "#18328D"
              });
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    getDataTable() {
      WebService.getallHistory().then(
        res => {
          this.itemsuser = res.data.data;
          this.totalRows = res.data.totalCount;
        },
        error => {
          console.log(error);
          this.itemsuser = [];
          this.totalRows = 0;
          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    resetFormEdit() {
      this.formEdit = {
        tiketid: "",
        siteid: "",
        sitelocation: "",
        saber: "",
        problem: "",
        description: ""
      };

      this.$nextTick(() => {
        this.$bvModal.hide("edit-user-modal");
      });
    },
    hideModal() {
      this.$refs["my-modaledit"].hide();
    },
    hapusvisit(index) {
      this.items.splice(index, 1);
    },
    lihatSemua(data) {
      this.editearly = true;
      if (data.row.data.asal_tiket == "ticket_support") {
        const store = new CustomStore({
          key: "id",
          load: function(loadOptions) {
            let params = "?";
            [
              "skip",
              "take",
              "requireTotalCount",
              "requireGroupCount",
              "sort",
              // "filter",
              "totalSummary",
              "group",
              "groupSummary"
            ].forEach(function(i) {
              if (i in loadOptions && isNotEmpty(loadOptions[i])) {
                params += `${i}=${JSON.stringify(loadOptions[i])}&`;
              }
            });
            params = params.slice(0, -1);

            // search by name if exists

            if (
              Array.isArray(loadOptions["filter"]) &&
              loadOptions["filter"].length > 0 &&
              loadOptions["filter"][0] === "name"
            ) {
              params += "&nama=" + loadOptions["filter"][2];
            }
            const user = JSON.parse(localStorage.getItem("user"));
            const headers = { Authorization: `Bearer ${user.access_token}` };
            return fetch(
              process.env.VUE_APP_URL_LOCAL +
                `/ticket-support-items/${data.row.data.original_ticket_id}${params}`,
              {
                headers
              }
            )
              .then(response => response.json())
              .then(data => {
                if (data.status == "error") {
                  return {
                    data: [],
                    totalCount: 0,
                    summary: null,
                    groupCount: 0
                  };
                } else {
                  return {
                    data: data.data,
                    totalCount: data.totalCount,
                    summary: data.summary,
                    groupCount: data.groupCount
                  };
                }
              })
              .catch(() => {
                // throw "Data Loading Error";
                return {
                  data: [],
                  totalCount: 0,
                  summary: null,
                  groupCount: 0
                };
              });
          }
        });
        this.dataSourceView = store;
      } else {
        const store = new CustomStore({
          key: "id",
          load: function(loadOptions) {
            let params = "?";
            [
              "skip",
              "take",
              "requireTotalCount",
              "requireGroupCount",
              "sort",
              // "filter",
              "totalSummary",
              "group",
              "groupSummary"
            ].forEach(function(i) {
              if (i in loadOptions && isNotEmpty(loadOptions[i])) {
                params += `${i}=${JSON.stringify(loadOptions[i])}&`;
              }
            });
            params = params.slice(0, -1);

            // search by name if exists

            if (
              Array.isArray(loadOptions["filter"]) &&
              loadOptions["filter"].length > 0 &&
              loadOptions["filter"][0] === "name"
            ) {
              params += "&nama=" + loadOptions["filter"][2];
            }
            const user = JSON.parse(localStorage.getItem("user"));
            const headers = { Authorization: `Bearer ${user.access_token}` };
            return fetch(
              process.env.VUE_APP_URL_LOCAL +
                `/ticket-process-items/${data.row.data.original_ticket_id}${params}`,
              {
                headers
              }
            )
              .then(response => response.json())
              .then(data => {
                if (data.status == "error") {
                  return {
                    data: [],
                    totalCount: 0,
                    summary: null,
                    groupCount: 0
                  };
                } else {
                  return {
                    data: data.data,
                    totalCount: data.totalCount,
                    summary: data.summary,
                    groupCount: data.groupCount
                  };
                }
              })
              .catch(() => {
                // throw "Data Loading Error";
                return {
                  data: [],
                  totalCount: 0,
                  summary: null,
                  groupCount: 0
                };
              });
          }
        });
        this.dataSourceView = store;
      }
      // dataSourceView
    },
    iconEdit(e) {
      this.editvisit = true;
      this.detaildata = e.row.data;
      console.log(this.detaildata);
      this.formEdit.id = this.detaildata.id;
      this.formEdit.ticket_id = this.detaildata.ticket_id;
      this.formEdit.site_id = this.detaildata.site_id;
      this.formEdit.site_location = this.detaildata.site_location;
      this.formEdit.saber = this.detaildata.saber;
      this.formEdit.problem = this.detaildata.problem;
      this.formEdit.last_visit = this.detaildata.last_visit;
      this.formEdit.description = this.detaildata.description;
      WebService.gethistoryMaintenance(this.detaildata.site_id)
        .then(res => {
          this.itemhistory = res.data.data;
        })
        .catch(error => {
          console.log(error);
          this.itemhistory = [];
        });
    }
  }
};
</script>

<style scoped>
.slot {
  background-color: #ebf4f8;
  padding: 0.5rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.btn .btn-primary .btn-sm {
  margin-right: 12px;
}
.ranges {
  display: none;
}
.text-black {
  color: #000;
}
</style>
